<template>
  <div>
    <RoleBreadcrumb
      :steps="breadcrumb"
    />

    <v-container
      v-if="role"
      class="edit-role"
      fluid
    >
      <h2 class="role__page-title">
        {{ $t('Role.createEditRolePage.editTitle') }}
      </h2>

      <StTabs
        :tabs="tabs"
        :initial-tab="initialTab"
      >
        <template #tab-content>
          <StTabItem
            :value="tabs[0].href"
            lazy
          >
            <v-form
              ref="form"
              v-model="valid"
            >
              <RoleNameEdit
                :name="role.name"
                :rules="nameRules"
                :role="role"
                @role-name-changed="name = $event"
              />
              <hr>
              <RolePermissions
                :role="role"
                :active-permissions="role.permissions"
                @permissions-changed="permissions = $event"
              />
              <SubmitBar
                :confirm-btn-text="$t('Role.createEditRolePage.editTitle')"
                :disabled="role.internal || !valid"
                :loading="loading"
                @on-submit-clicked="onSubmit"
              />
            </v-form>
          </StTabItem>
          <StTabItem
            :value="tabs[1].href"
            lazy
          >
            <UsersInRole />
          </StTabItem>
        </template>
      </StTabs>
    </v-container>
  </div>
</template>

<script>
import RoleBreadcrumb from '@/components/permissions/RoleBreadcrumb';
import RoleNameEdit from '@/components/permissions/RoleNameEdit';
import RolePermissions from '@/components/permissions/RolePermissions';
import SubmitBar from '@/components/permissions/SubmitBar';
import UsersInRole from '@/components/permissions/UsersInRole';
import { mapState, mapActions } from 'vuex';
import { requiredRule, notEmpty, maxLength } from '@/utils/form_rules';
import requestStatusMixin from '@/mixins/request_status.mixin';
import StTabs from '@stilingue/st-tabs/StTabs';
import StTabItem from '@stilingue/st-tabs/StTabItem';

export default {
  name: 'EditRole',
  components: {
    RoleNameEdit,
    RolePermissions,
    SubmitBar,
    UsersInRole,
    RoleBreadcrumb,
    StTabs,
    StTabItem
  },
  mixins: [requestStatusMixin],
  data() {
    return {
      role: {},
      name: '',
      permissions: [],
      activePermissions: [],
      valid: false,
      nameRules: [
        requiredRule(this.$t('FormRules.nameRules.required')),
        notEmpty(this.$t('FormRules.nameRules.notEmpty')),
        maxLength(100, this.$tc('FormRules.nameRules.maxLength', 100, { max: 100 }))
      ],
      activeTab: this.$route.params.goToTab,
      breadcrumb: [
        {
          title: this.$t('Role.roleList.roleListSubtitle'),
          path: '/perfis/'
        }
      ],
      tabs: [{
        href: 'edit-role',
        title: this.$t('Role.createEditRolePage.editTitle')
      }, {
        href: 'users-in-role',
        title: this.$t('Role.createEditRolePage.usersInRole')
      }]
    };
  },
  computed: {
    ...mapState('role', [
      'roles',
      'requestStatus'
    ]),
    /**
     * InitialTab return the index of the initialTab received
     * from the VueRouter though the parameter goToTab
     * @return {Number}: Index of target tab if goToTab in tabs 0 otherwise
     **/
    initialTab() {
      const tabIndex = this.tabs.findIndex(tab => tab.href === this.$route.params.goToTab);
      return Math.max(tabIndex, 0);
    }
  },
  beforeRouteEnter(to, from, next) {
    /* istanbul ignore next  */
    next(async (vm) => {
      await vm.findRole();
      next();
    });
  },
  created() {
    this.findRole();
  },
  methods: {
    ...mapActions('role', [
      'getRoles',
      'saveRole'
    ]),
    async findRole() {
      const roleId = this.$route.params.id;

      try {
        !this.roles.length && await this.getRoles();
        const role = this.roles.find(role => Number(role.id) === Number(roleId));

        if (!role) {
          /* istanbul ignore next */
          return this.$router.replace({ name: 'role-list' })
            .catch(/*istanbul ignore next*/() => {});
        }

        this.role = role;
        this.name = role.name;
        this.permissions = role.permissions.map(permission => {
          return {
            isActive: true,
            id: permission
          };
        });

        this.breadcrumb.splice(1, 1, {
          title: this.name,
          path: `/perfis/editar/${roleId}`
        });
      } catch (error) {
        return this.$router.replace({ name: 'role-list' })
          .catch(/*istanbul ignore next*/ () => {});
      }
    },
    async onSubmit() {
      const permissions = this.permissions
        .filter(permission => permission.isActive)
        .map(permission => permission.id);

      if (!permissions.length) {
        const errorMsg = this.$tc('FormRules.permissionsRules.minLength', 1, { min: 1 });
        this.$noty.error(errorMsg);
        return;
      }

      const role = {
        name: this.name,
        permissions
      };

      try {
        await this.saveRole({ roleId: this.role.id, role });
        return this.$router.push({ name: 'role-list' });
      } catch (error) {
        const errorMsg = this.$t('Role.createEditRolePage.errorEditingRole');
        this.$noty.error(errorMsg);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .role {
    &__page-title {
      font-weight: bold;
    }
  }
</style>
