var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("RoleBreadcrumb", { attrs: { steps: _vm.breadcrumb } }),
      _vm.role
        ? _c(
            "v-container",
            { staticClass: "edit-role", attrs: { fluid: "" } },
            [
              _c("h2", { staticClass: "role__page-title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("Role.createEditRolePage.editTitle")) +
                    "\n    "
                )
              ]),
              _c("StTabs", {
                attrs: { tabs: _vm.tabs, "initial-tab": _vm.initialTab },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "tab-content",
                      fn: function() {
                        return [
                          _c(
                            "StTabItem",
                            { attrs: { value: _vm.tabs[0].href, lazy: "" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  model: {
                                    value: _vm.valid,
                                    callback: function($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid"
                                  }
                                },
                                [
                                  _c("RoleNameEdit", {
                                    attrs: {
                                      name: _vm.role.name,
                                      rules: _vm.nameRules,
                                      role: _vm.role
                                    },
                                    on: {
                                      "role-name-changed": function($event) {
                                        _vm.name = $event
                                      }
                                    }
                                  }),
                                  _c("hr"),
                                  _c("RolePermissions", {
                                    attrs: {
                                      role: _vm.role,
                                      "active-permissions": _vm.role.permissions
                                    },
                                    on: {
                                      "permissions-changed": function($event) {
                                        _vm.permissions = $event
                                      }
                                    }
                                  }),
                                  _c("SubmitBar", {
                                    attrs: {
                                      "confirm-btn-text": _vm.$t(
                                        "Role.createEditRolePage.editTitle"
                                      ),
                                      disabled: _vm.role.internal || !_vm.valid,
                                      loading: _vm.loading
                                    },
                                    on: { "on-submit-clicked": _vm.onSubmit }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "StTabItem",
                            { attrs: { value: _vm.tabs[1].href, lazy: "" } },
                            [_c("UsersInRole")],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  594845847
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }