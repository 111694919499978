<template>
  <div id="users-in-role">
    <v-container
      class="users-in-role__header pa-0 mt-12 mb-6"
      fluid
    >
      <StIcon
        class="users-in-role__icon"
        icon-name="account-plus"
      />
      <span class="users-in-role__title">
        {{ $t('Role.createEditRolePage.usersInRole') }}
      </span>
      <span class="users-in-role__title-info">
        {{ $t('Role.createEditRolePage.usersInRoleDescription') }}
      </span>
    </v-container>

    <UserRoleTable
      :role-to-filter="activeRole"
    />
  </div>
</template>

<script>
import StIcon from '@stilingue/st-icon';
import UserRoleTable from '@/components/permissions/UserRoleTable';

export default {
  name: 'UsersInRole',
  components: {
    StIcon,
    UserRoleTable
  },
  data() {
    return {
      activeRole: this.$route.params.id
    };
  }
};
</script>

<style lang="scss" scoped>
  .users-in-role__header {
    display: grid;
    grid-template-columns: 24px auto;
    grid-column-gap: 14px;
    grid-row-gap: 8px;
    grid-template-areas: "icon title" ". description";
  }

  .users-in-role {
    &__icon {
      grid-area: icon;
    }

    &__title {
      grid-area: title;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      color: var(--gray-200);
    }

    &__title-info {
      grid-area: description;
      font-size: 12px;
      line-height: 1.5;
      text-align: left;
      color: var(--gray-200);
    }
  }
</style>
