var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "users-in-role" } },
    [
      _c(
        "v-container",
        {
          staticClass: "users-in-role__header pa-0 mt-12 mb-6",
          attrs: { fluid: "" }
        },
        [
          _c("StIcon", {
            staticClass: "users-in-role__icon",
            attrs: { "icon-name": "account-plus" }
          }),
          _c("span", { staticClass: "users-in-role__title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("Role.createEditRolePage.usersInRole")) +
                "\n    "
            )
          ]),
          _c("span", { staticClass: "users-in-role__title-info" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("Role.createEditRolePage.usersInRoleDescription")
                ) +
                "\n    "
            )
          ])
        ],
        1
      ),
      _c("UserRoleTable", { attrs: { "role-to-filter": _vm.activeRole } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }