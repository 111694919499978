<template>
  <div
    class="role-breadcrumb"
  >
    <StBreadcrumb
      :steps="pages"
      @navigate-to="onClickChild"
    />
  </div>
</template>

<script>
import StBreadcrumb from '@stilingue/st-breadcrumb';
export default {
  name: 'RoleBreadcrumb',
  components: {
    StBreadcrumb
  },
  props: {
    steps: {
      type: Array,
      default() {
        return [{
          title: this.$t('Role.roleList.roleListSubtitle'),
          path: '/perfis/'
        }];
      }
    }
  },
  data() {
    return {
      pages: this.steps
    };
  },
  methods: {
    onClickChild(value) {
      return this.$router.replace({ path: value }).catch(/*istanbul ignore next*/() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
  .role-breadcrumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
</style>
