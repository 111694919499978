var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-breadcrumb" },
    [
      _c("StBreadcrumb", {
        attrs: { steps: _vm.pages },
        on: { "navigate-to": _vm.onClickChild }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }